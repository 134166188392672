<template>
  <el-card class="box-card full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item v-if="loginInfo.orgType==1" :label="$t('affiliationOrg')">
          <el-select v-model="queryForm.orgInfoId" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option v-for="(t,i) in orgList" :key="i" :label="t.name+' -- '+(t.orgInfoCode)" :value="t.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('query object')">
          <el-select v-model="queryForm.customerType" size="small">
            <el-option :label="$t('remitterInfo')" :value="1" selected></el-option>
            <el-option :label="$t('beneficiaryInfo')" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('linkPhone')">
          <el-input v-model="queryForm.phone" size="small"></el-input>
        </el-form-item>
        <el-form-item :label="$t('idNumber')">
          <el-input v-model="queryForm.idCard" size="small"></el-input>
        </el-form-item>
        <el-form-item v-if="queryForm.customerType==2" :label="$t('status')">
          <el-select v-model="queryForm.status" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('already withdrawal')" value="2"></el-option>
            <el-option :label="$t('without withdrawal')" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isloading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isloading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column align="center" :label="$t('remittanceInfo')" min-width="160" class-name="multiple-header-intervals">
          <el-table-column prop="" :label="$t('compellation')" min-width="160">
            <template slot-scope="scope">{{scope.row.remitterName}} {{scope.row.remitterSurname}}</template>
          </el-table-column>
          <el-table-column prop="remitterPhone" :label="$t('linkPhone')" min-width="160"></el-table-column>
          <el-table-column prop="remitterIdCard" :label="$t('idNumber')" min-width="160"></el-table-column>
          <el-table-column prop="remitterAmount" :label="$t('remittanceAmount')" min-width="160">
            <template slot-scope="scope"><span class="franc">₣</span>{{scope.row.remitterAmount.replace("-","").split(".")[0].formatMoney(0)}}</template>
          </el-table-column>
          <el-table-column prop="remitterCreateTime" :label="$t('remittance time')" min-width="160">
            <template slot-scope="scope">{{scope.row.remitterCreateTime?$manba(scope.row.remitterCreateTime).format('ymdhms'):""}}</template>
          </el-table-column>
          <el-table-column prop="remitterOrgInfoId" :label="$t('operateOrg')" min-width="160" class-name="multiple-header-intervals"></el-table-column>
        </el-table-column>
        <el-table-column align="center" :label="$t('withdrawalInfo')" min-width="160">
          <el-table-column prop="" :label="$t('compellation')" min-width="160">
            <template slot-scope="scope">{{scope.row.payeeName}} {{scope.row.payeeSurname}}</template>
          </el-table-column>
          <el-table-column prop="payeePhone" :label="$t('linkPhone')" min-width="160"></el-table-column>
          <el-table-column prop="payeeIdCard" :label="$t('idNumber')" min-width="160"></el-table-column>
          <el-table-column prop="address" :label="$t('address')" min-width="200">
            <template slot-scope="scope">{{getRegionName([scope.row.country,scope.row.region,scope.row.city])}}</template>
          </el-table-column>
          <el-table-column prop="status" :label="$t('status')" min-width="160">
            <template slot-scope="scope">
              <span v-if="scope.row.status==2" type="text-success">{{$t('already withdrawal')}}</span>
              <span v-if="scope.row.status==1" type="text-warning">{{$t('without withdrawal')}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="payeeAmount" :label="$t('withdrawalAmount')" min-width="160">
            <template slot-scope="scope"><span class="franc">₣</span>{{scope.row.payeeAmount.split(".")[0].formatMoney(0)}}</template>
          </el-table-column>
          <el-table-column prop="payeeDoneTime" :label="$t('withdrawal time')" min-width="160">
            <template slot-scope="scope">{{scope.row.payeeDoneTime?$manba(scope.row.payeeDoneTime).format('ymdhms'):""}}</template>
          </el-table-column>
          <el-table-column prop="payeeOrgName" :label="$t('operateOrg')" min-width="160"></el-table-column>
        </el-table-column>
        <el-table-column align="center" fixed="right" :label="''" min-width="200">
          <el-table-column :label="$t('extend')" min-width="200">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status==1" size="mini" type="primary" @click="visibleEdit(scope.row)">{{$t('edit payee info')}}</el-button>
              <el-button v-else size="mini" disabled>{{$t('edit payee info')}}</el-button>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="$t('edit payee info')" :visible.sync="editVisible" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="700">
      <el-form :model="editForm" :rules="rules" ref="editForm">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('firstName')" prop="payeeName">
              <el-input v-model="editForm.payeeName" :placeholder="$t('firstName')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('linkPhone')" prop="payeePhone">
              <el-input v-model="editForm.payeePhone" :placeholder="$t('linkPhone')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('address')" prop="country_region_city">
              <el-cascader v-model="editForm.country_region_city" :options="countryTree" :props="cascader"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('lastName')" prop="payeeSurname">
              <el-input v-model="editForm.payeeSurname" :placeholder="$t('lastName')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('idNumber')" prop="payeeIdCard">
              <el-input v-model="editForm.payeeIdCard" :placeholder="$t('idNumber')"></el-input>
            </el-form-item>
            <el-form-item prop="smsCode">
              <template solt="tabel">
                <span>{{$t('messageAuthenticationCode')}}</span>
                <span style="font-size:12px;color:#ff5722;margin-left:10px">{{$t("to remitter")}}</span>
              </template>
              <el-input v-model="editForm.smsCode" :placeholder="$t('messageAuthenticationCode')">
                <el-button slot="append" @click="getSmsVerification" :disabled="surplusTime>0">
                  <span v-if="!surplusTime">{{$t('Send SMS verification code')}}</span>
                  <span v-else>{{$t("send it again")+`(${surplusTime})`}}</span>
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="bottom-button">
          <el-button size="medium" type="primary" @click="updateSubmit('editForm')" :loading="isloading">{{$t('submit')}}</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-card>
</template>

<script>
import org from '@/api/org'
import transactionInfo from '@/api/transactionInfo'
export default {
  name: "remittanceList",
  data () {
    return {
      queryForm: { customerType: 1 },
      // queryForm: { queryObject: 1 },
      queryTableHeight: String,
      orgList: [],
      tableData: {},
      pageSize: 20,
      currentPage: 1,
      isloading: false,

      editVisible: false,
      editForm: {},
      surplusTime: 0,
    }
  },
  computed: {
    loginInfo () { return this.$store.state.globalParameter.loginInfo; },
    cascader () { return this.$store.state.format.cascader },
    countryTree () { return this.$store.state.globalParameter.countryTree; },
    countryList () { return this.$store.state.globalParameter.countryList; },
    rules () {
      return {
        smsCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payeeName: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payeePhone: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        country_region_city: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payeeSurname: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payeeIdCard: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    }
  },
  methods: {
    getList () {
      let _this = this;
      _this.isLoading = true;
      transactionInfo.pageRemittanceList({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.isLoading = false;
          _this.tableData = res.data || {};

        }
      })
    },
    getRegionName (arr) {
      let name = "";
      for (let i in arr) {
        let tt = this.countryList.find(function (t) { return t.id == arr[i] });
        name += tt ? (tt.name + ' ') : "";
      }
      return name.substring(0, name.length - 1)
    },
    getOrgList () {
      let _this = this;
      org.getOrgInfoPage({
        param: { current: 1, size: 10000, },
        success: res => {
          if (res.data)
            _this.orgList = res.data.records;
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    getSmsVerification () {
      let _this = this;
      transactionInfo.sendVerificationCode({
        param: { phone: this.editForm.remitterPhone }, success: function (res) {
          if (res.code != 200) {
            _this.$message.warning(_this.$t(res.msg))
            return
          }
          _this.surplusTime = 60;
          let interval = setInterval(() => {
            _this.surplusTime -= 1;
            if (_this.surplusTime === 0)
              clearInterval(interval);
          }, 1000)
          _this.$message.success(_this.$t("SMS verification code sent successfully") + "，" + _this.$t("Verification code Receiving number") + _this.editForm.remitterPhone);
        }
      })
    },
    visibleEdit (row) {
      this.editForm = row;
      this.editForm.country_region_city = [Number(row.country), Number(row.region), Number(row.city)];
      this.editVisible = true;
    },
    updateSubmit (refName) {
      let _this = this
      this.$refs[refName].validate(function (valid) {
        if (!valid) return false;
        let update = {
          payeeDetailsId: _this.editForm.payeeDetailsId,
          remitterPhone: _this.editForm.remitterPhone,
          surname: _this.editForm.payeeSurname,
          name: _this.editForm.payeeName,
          phone: _this.editForm.payeePhone,
          idCard: _this.editForm.payeeIdCard,
          country: _this.editForm.country_region_city[0],
          region: _this.editForm.country_region_city[1],
          city: _this.editForm.country_region_city[2],
          smsCode: _this.editForm.smsCode
        }

        transactionInfo.updatePayeeInfo({
          param: update,
          success: res => {
            if (res.code == 200) {
              _this.$message.success(_this.$t('operateSuccessfully'));
              _this.editVisible = false;
              _this.getList()
            }
            else
              _this.$message.warning(_this.$t(res.msg || 'operationFailure'));
          }
        })
      })
    },
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';

    this.getList()

    this.getOrgList();
  }
}
</script>
<style lang="less" >
</style>